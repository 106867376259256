import React, { useEffect, useState } from 'react';
import editRoundTastersStyle from './editroundtasters.module.scss';
import PropTypes from 'prop-types';
import {
  Label,
  Card,
  CardRow,
  Checkbox,
  UserImage,
  AutoComplete,
  Button,
} from '../../../components';
import UserService from '../../services/users/user.service';
import GroupService from '../../services/groups/group.service';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';
import { HelpCircle, Key, Mail } from 'react-feather';
import { useUser } from '../../../contexts/User';
import ReactTooltip from 'react-tooltip';
import GuestChanged from '../../../assets/guest_changed.png'
import GuestUnChange from '../../../assets/guest_un_change.png'

const EditRoundTasters = props => {
  const { t } = useTranslation();

  const userService = new UserService();
  const groupService = new GroupService();
  const roundService = new RoundService();
  const { user } = useUser();
  const isZhOrKr = JSON.parse(
    user.str_language === 'zh' || user.str_language === 'kr',
  );
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  useEffect(() => {
    roundService.listRoundUsers(props.round.uuid_round).then(
      res => {
        if (res.type === 'success' && res.results) {
          props.setRound({
            ...props.round,
            arr_users: res.results.map(taster => {
              if (taster.bol_abi_account === false) {
                taster.bol_guest = true;
              }
              return { ...taster, bol_taster: 1 };
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  }, []);

  const handleSearchGroups = text => {
    return new Promise((resolve, reject) => {
      groupService.findGroup({ str_name: text }).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            window.alert(res.message);
            reject(res.message);
          }
        },
        error => {
          //console.log(error);
          reject(error);
        },
      );
    });
  };

  const handleSearchTasters = text => {
    return new Promise((resolve, reject) => {
      userService.findUser({ str_name: text }).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            window.alert(res.message);
            reject(res.message);
          }
        },
        error => {
          //console.log(error);
          reject(error);
        },
      );
    });
  };

  const handleSelectGroup = evt => {
    if (
      evt.target &&
      evt.target.value &&
      evt.target.value.arr_taste_groups_users &&
      evt.target.value.uuid_taste_group
    ) {
      const roundUsers = evt.target.value.arr_taste_groups_users.map(item => {
        if (item.bol_abi_account === false) {
          item.bol_guest = true;
        }
        return item
      });
      props.setRound({
        ...props.round,
        arr_users: roundUsers,
        uuid_taste_group: evt.target.value.uuid_taste_group,
        str_name_taste_group: evt.target.value.str_name,
      });
    }
  };

  const handleSelectTaster = evt => {
    if (evt.target && evt.target.value) {
      const hasUser = props.round.arr_users?.find(
        u => u.uuid_user === evt.target.value.uuid_user,
      );

      if (hasUser) return;

      const newUser = evt.target.value;
      newUser.bol_key_taster = 0;

      const roundUsers = props.round.arr_users
        ? props.round.arr_users.concat([newUser])
        : [newUser];
      props.setRound({
        ...props.round,
        arr_users: roundUsers.map(item => {
          if (item.bol_abi_account === false) {
            item.bol_guest = true;
          }
          return item
        }),
      });
    }
  };

  const handleCheckTaster = (evt, index) => {
    let usersTemp = props.round.arr_users;
    if (
      typeof props.round.arr_status === 'object' &&
      props.round.arr_status.length >= 4 &&
      props.round.arr_status[3] === 'IN_PROGRESS' &&
      props.round.str_round_type === 'Flavor ID'
    ) {
      usersTemp[index].bol_taster = true;
    } else {
      usersTemp[index].bol_taster = !usersTemp[index].bol_taster;
    }
    props.setRound({
      ...props.round,
      arr_users: usersTemp
    });
  };

  const selectOrUnselectAll = isSelectAll => {
    let usersTemp = props.round.arr_users;
    for (let user of usersTemp) {
      user.bol_taster = isSelectAll
      if (user.bol_abi_account === false) {
        user.bol_guest = true;
      }
    };
    props.setRound({
      ...props.round,
      arr_users: usersTemp,
    });
  };

  const handleCheckAllTasters = () => {
    selectOrUnselectAll(true);
  };

  const handleUnselectAllTasters = () => {
    selectOrUnselectAll(false);
  };

  const handleSetUserAsKeyTaster = user => {
    const { uuid_user } = user;
    roundService.setUserAsKeyTaster(props.round.uuid_round, uuid_user).then(
      res => {
        if (res.type === 'success' && res.results) {
          const tempUsers = props.round.arr_users;
          props.setRound({
            ...props.round,
            arr_users: tempUsers.map(u => {
              if (u.uuid_user == uuid_user) {
                return { ...u, bol_key_taster: 1 };
              } else {
                return u;
              }
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleUnsetUserAsKeyTaster = user => {
    const { uuid_round, uuid_user } = user;
    roundService.unsetUserAsKeyTaster(uuid_round, uuid_user).then(
      res => {
        if (res.type === 'success' && res.results) {
          const tempUsers = props.round.arr_users;
          props.setRound({
            ...props.round,
            arr_users: tempUsers.map(u => {
              if (u.uuid_user == uuid_user) {
                return { ...u, bol_key_taster: 0 };
              } else {
                return u;
              }
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };
  const handleChangeGuest = (checked, user) => {
    const { uuid_user } = user;
    const url = checked == false ? '/round/remove/guest' : '/round/add/guest'
    roundService.setUserAsGuest(url, props.round.uuid_round, uuid_user).then(
      res => {
        if (res.type === 'success' && res.results) {
          const tempUsers = props.round.arr_users;
          props.setRound({
            ...props.round,
            arr_users: tempUsers.map(u => {
              if (u.uuid_user == uuid_user) {
                return { ...u, bol_guest: checked };
              } else {
                return u;
              }
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };
  const handleTasterList = (user, index) => {
    return (
      <div key={index} className={editRoundTastersStyle.tasterCard}>
        <Checkbox
          label=""
          name="bol_taster"
          value={user.bol_taster}
          margin="2px 0px 0px 15px"
          onChange={evt => {
            handleCheckTaster(evt, index);
          }}
        />
        <div
          className={editRoundTastersStyle.userImage}
          style={{ minWidth: '50px', marginRight: '10px' }}
        >
          <UserImage
            name="str_user_image"
            src={user.str_image}
            margin="0px 6px 0px 0px"
            size={50}
          />
        </div>
        <div className={editRoundTastersStyle.tasterCardText}>
          <div className={editRoundTastersStyle.tasterNameLabel}>
            <Label
              label={user.str_name}
              bold
              neutral
              size={14}
              style={{ maxWidth: '245px' }}
              hidenText
              dataTip={user.str_name}
              dataForId="userName"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                user.bol_abi_account !== false &&
                <div>
                  {user.bol_key_taster ? (
                    <Key
                      onClick={() => handleUnsetUserAsKeyTaster(user)}
                      className={editRoundTastersStyle.keyIcon}
                      size={24}
                      data-tip={t('common_key_taster')}
                      data-for='KeyTaster'
                    />
                  ) : (
                    <Key
                      onClick={() => handleSetUserAsKeyTaster(user)}
                      className={editRoundTastersStyle.keyIconInactive}
                      size={24}
                      data-tip={t('common_key_taster')}
                      data-for='KeyTaster'
                    />
                  )}
                  <ReactTooltip
                    id='KeyTaster'
                    effect="solid"
                    place="top"
                    type="warning"
                  />
                </div>
              }
              <div>
                {
                  user.bol_guest ?
                    <div
                      onClick={() => {
                        if (user.bol_abi_account !== false) {
                          handleChangeGuest(false, user)
                        }
                      }}
                      className={editRoundTastersStyle.keyIcon}
                      style={{
                        "cursor": user.bol_abi_account !== false ? 'pointer' : 'not-allowed',
                      }}
                      data-tip={t('guest')}
                      data-for='Guest'
                    >
                      <img src={GuestChanged} width='12px' />
                    </div> :
                    <div
                      onClick={() => {
                        if (user.bol_abi_account !== false) {
                          handleChangeGuest(true, user)
                        }
                      }}
                      className={editRoundTastersStyle.keyIconInactive}
                      data-tip={t('guest')}
                      style={{
                        "cursor": user.bol_abi_account !== false ? 'pointer' : 'not-allowed',
                      }}
                      data-for='Guest'
                    >
                      <img src={GuestUnChange} width='12px' />
                    </div>
                }
                <ReactTooltip
                  id='Guest'
                  effect="solid"
                  place="top"
                  type="warning"
                />
              </div>
            </div>
          </div>
          <Label label={user.str_abi_id} neutral size={12} />
          <Label label={user.str_email} neutral size={12} />
          {user.bol_abi_account == false &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
              <Button
                height='20px'
                blue
                style={{
                  padding: '2px 0',
                  width: '57px',
                  marginRight: '5px',
                }}
                dataTip={t('send_email')}
                onClick={() => {
                  handleEmail(user)
                  props.setRound({
                    ...props.round,
                    arr_users: props.round.arr_users.map(u => {
                      if (u.uuid_user === user.uuid_user) {
                        return { ...u, emailLoading: true };
                      } else {
                        return u;
                      }
                    }),
                  });
                  setTimeout(() => {
                    window.alert(t('email_invitation_delivery_request_sent'));
                    props.setRound({
                      ...props.round,
                      arr_users: props.round.arr_users.map(u => {
                        if (u.uuid_user === user.uuid_user) {
                          return { ...u, emailLoading: false };
                        } else {
                          return u;
                        }
                      }),
                    });
                  }, 300)
                }}
                loading={user.emailLoading}
              >
                <Mail color='#fff' size={16} />
              </Button>
            </div>
          }
        </div>
      </div >
    );
  };
  const handleEmail = (user) => {
    const arr_users =
      user ?
        [user] :
        props.round.arr_users?.filter((user) => user.bol_abi_account == false);
    const { uuid_round, dat_start, str_name_round, uuid_zone, str_zone_name, uuid_country, str_country_name, uuid_site, str_site_name, } = props.round
    const round_sp_in_up_admin_round = props.roundId
    roundService.sendEmailToNonAbi({
      uuid_round,
      dat_start,
      str_name_round,
      uuid_zone,
      uuid_country,
      uuid_site,
      str_zone_name,
      str_country_name,
      str_site_name,
      round_sp_in_up_admin_round,
      arr_users,
    })
  }
  return (
    <Card>
      <Label
        label={
          props.round.str_name_taste_group
            ? props.round.str_name_taste_group
            : 'Search a Group...'
        }
        bold
        neutral
        margin="-10px 0px 20px 0px"
      />
      <CardRow flexWrap="wrap">
        <AutoComplete
          useDebounce
          minimalToSearch={isZhOrKr ? 2 : 3}
          disabled={
            typeof props.round.arr_status === 'object' &&
            props.round.arr_status.length >= 4 &&
            props.round.arr_status[3] === 'IN_PROGRESS' &&
            props.round.str_round_type === 'Flavor ID'
          }
          name="group"
          label={t('common_search_group')}
          margin="10px 10px 0px 0px"
          padding="11px 5px 11px 34px"
          optionKeys={['str_name']}
          onSearch={handleSearchGroups}
          onSelect={handleSelectGroup}
        />
        <AutoComplete
          useDebounce
          minimalToSearch={isZhOrKr ? 2 : 3}
          name="tasters"
          label={t('common_search_taster')}
          margin="10px 10px 0px 0px"
          optionKeys={['str_abi_id', 'str_name', 'str_email']}
          onSearch={handleSearchTasters}
          onSelect={handleSelectTaster}
        />
      </CardRow>
      <CardRow justifyContent="flex-end" style={{ marginRight: '10px' }}>
        <Button
          label={t('round_tasters_unselect_all')}
          margin="10px 10px 0px 0px"
          onClick={handleUnselectAllTasters}
          disabled={
            typeof props.round.arr_status === 'object' &&
            props.round.arr_status.length >= 4 &&
            props.round.arr_status[3] === 'IN_PROGRESS' &&
            props.round.str_round_type === 'Flavor ID'
          }
          style={{
            borderRadius: '6px',
            width: '160px',
            backgroundColor: '#bbbdbf',
            color: 'white',
          }}
        />
        <Button
          label={t('round_tasters_select_all')}
          margin="10px 0px 0px 0px"
          onClick={handleCheckAllTasters}
          neutral
          style={{
            borderRadius: '6px',
            width: '160px',
          }}
        />
      </CardRow>
      {
        props.round.arr_users?.filter((user) => user.bol_abi_account != false).length > 0 &&
        <>
          <CardRow flexWrap="wrap">
            {props.round && props.round.arr_users
              ? props.round.arr_users.map((user, index) => {
                if (user.bol_abi_account != false) {
                  return handleTasterList(user, index)
                }
              })
              : ''}
          </CardRow>
        </>
      }
      {
        props.round.arr_users?.filter((user) => user.bol_abi_account != false).length > 0 &&
        props.round.arr_users?.filter((user) => user.bol_abi_account == false).length > 0 &&
        <>
          <hr style={{ margin: '16px 0' }} />
        </>
      }
      {
        props.round.arr_users?.filter((user) => user.bol_abi_account == false).length > 0 &&
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: '10px'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', }}>
              <p style={{ color: '#F49b00', fontWeight: '600' }}>{t('temporary_non_abi_taster')}</p>
              <div>
                <HelpCircle
                  data-tip={t('change_non_abi_users_to_round')}
                  style={{ color: '#B11F24', marginTop: 3, marginLeft: 10 }}
                  size="20"
                />
                <ReactTooltip
                  effect="solid"
                  place="right"
                  type="warning"
                  style={{ whiteSpace: 'normal' }}
                />
              </div>
            </div>
            <Button
              label={t('email_to_all')}
              margin="10px 0px 0px 0px"
              onClick={() => {
                handleEmail()
                setSendEmailLoading(true)
                setTimeout(() => {
                  setSendEmailLoading(false)
                  window.alert(t('email_invitation_delivery_request_sent'));
                }, 300)
              }}
              loading={sendEmailLoading}
              blue
              style={{
                borderRadius: '6px',
                width: '160px',
              }}
            />
          </div>
          <CardRow flexWrap="wrap">
            {props.round && props.round.arr_users
              ? props.round.arr_users.map((user, index) => {
                if (user.bol_abi_account == false) {
                  return handleTasterList(user, index)
                }
              })
              : ''}
          </CardRow>
        </>
      }
    </Card >
  );
};


export default EditRoundTasters;
