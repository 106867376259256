import MainService from './main.service';

export default class ReportService {
  mainService = null;
  path = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/admin';
  }
  generate = filter => {
    const arr_round_types = filter.arr_round_types[0];
    switch (arr_round_types) {
      case '4ed984d9-021d-4d06-a992-991a24b3d7ce':
        filter.arr_round_types = null;
        return this.mainService.post(`${this.path}/generate`, filter);
      case '43b11c76-043c-11eb-b18d-026fdc1cc8f2':
      case 'b8082a00-e1d1-11ec-97a3-000000000000':
        filter.arr_round_types = [arr_round_types];
        return this.mainService.post(`${this.path}/generate/freshness`, filter);
      case 'be229b18-2eb4-11eb-ba1d-0229531a6f14':
        filter.arr_round_types = null;
        return this.mainService.post(`${this.path}/generate/ok-nok`, filter);
      case '02095a80-6cc4-11ec-8d68-000000000000':
        filter.arr_round_types = null;
        return this.mainService.post(
          `${this.path}/generate/threshold/report`,
          filter,
        );
      case '0d55fca0-e7a2-11ec-a8bc-000000000000':
        filter.arr_round_types = null;
        return this.mainService.post(
          `${this.path}/generate/flavor_id/report`,
          filter,
        );
      case 'e4454b00-6950-11ed-bb18-000000000000':
        filter.arr_round_types = null;
        return this.mainService.post(
          `${this.path}/generate/triangle_test/report`,
          filter,
        );
      default:
        filter.arr_round_types = null;
        return this.mainService.post(`${this.path}/generate`, filter);
    }
  };
  generateProductionSite = filter => {
    return this.mainService.post(
      `${this.path}/generate/producing_site/report`,
      filter,
    );
  };

  generateOkNok = filter => {
    return this.mainService.post(`${this.path}/generate/ok-nok`, filter);
  };

  generateUsersReport = filter => {
    return this.mainService.post(`${this.path}/generate/users`, filter);
  };

  generateTemplateSamples = privileges => {
    return this.mainService.post(`${this.path}/template/samples`, privileges);
  };

  generateTemplateSamplesOkNok = privileges => {
    return this.mainService.post(
      `${this.path}/template/samples/ok-nok`,
      privileges,
    );
  };

  generateTemplateThreshold = uuid_round => {
    return this.mainService.post(`${this.path}/generate/threshold/design`, {
      uuid_round,
    });
  };

  generateKpiBrandReport = (uuid_brand, kpi_year) => {
    return this.mainService.post(`${this.path}/generate/kpi_brand/report`, {
      uuid_brand,
      kpi_year,
    });
  };

  generateKpiBrandPanelReport = (uuid_brand, kpi_year) => {
    return this.mainService.post(
      `${this.path}/generate/kpi_brand/panel/report`,
      {
        uuid_brand,
        kpi_year,
      },
    );
  };

  generateSampleLabels = obj => {
    return this.mainService.post(`${this.path}/generate/sample_labels`, obj);
  };

  downloadFile = body => {
    return this.mainService.post(
      `${this.path}/generate/triangle_test/design
      `,
      body,
    );
  };
  downloadProcessCheck = body => {
    return this.mainService.post(
      `${this.path}/generate/process_check
      `,
      body,
    );
  };

  generateBSSIReport = (str_year, uuid_zone) => {
    return this.mainService.post(`${this.path}/generate/bssi/report`, {
      str_year,
      uuid_zone,
    });
  };
}
