import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/User";
import Login from '../modules/Login/Login';
import LoginSSO from '../modules/LoginSSO/LoginSSO';
import LoginAuth from '../modules/LoginAuth/LoginAuth'
import Template from "../modules/Template/Template";
import _ from "lodash";
import { TourMaskProvider } from "../components/tourMask/tourMaskHooks";
import TourMask from "../components/tourMask/tourMask";

const Routes = () => {
  const { isLogged } = useUser();
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // if (
      //   !isLogged &&
      //   window.location.pathname !== "/" &&
      //   window.location.pathname.indexOf('/login_auth') === -1
      // ) {
      //   window.location.href = '/';
      // }
      // const existingScript = document.querySelector('script[src="https://nps-new-stg.ambevdevs.com.br"]');
      // if (isLogged && !existingScript) {
      //   let scriptDom = document.createElement('script');
      //   scriptDom.src = 'https://nps-new-stg.ambevdevs.com.br';
      //   scriptDom.setAttribute('host', 'https://nps-new-stg.ambevdevs.com.br/nps');
      //   scriptDom.setAttribute('system', 'f7c0ba84-e8a7-42e2-0358-08dca5543c34');
      //   scriptDom.setAttribute('auth', 'idtoken');
      //   scriptDom.setAttribute('property', 'idToken');
      //   scriptDom.setAttribute('storage', 'local');
      //   document.body.appendChild(scriptDom);
      // }
      loadCSS('./font/iconfont.css');
      loadCSS('./fontSize/font.css');
    }, 500)
  }, [isLogged])

  useEffect(() => {
    setIsTourOpen(true)
  }, [])

  const loadCSS = (url) => {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
  }

  const { pathname } = window.location;

  return (
    <TourMaskProvider>
      {
        pathname.indexOf('/login_auth') > -1 ?
          <LoginAuth /> :
          !isLogged ? ((
            process.env.REACT_APP_STAGE === "local" ||
              process.env.REACT_APP_STAGE === "dev" ? (
              <Login />
            ) : (
              <LoginSSO />
            )
          )) : (
            <Template />
          )
      }
      {isTourOpen &&
        <TourMask
          key={pathname + '/'}
        />
      }
    </TourMaskProvider>
  )
};

export default Routes;