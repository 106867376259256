import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/User';
import menuStyle from './menu.module.scss';
import { checkVersion } from '../../utils/checkVersion';

const Menu = props => {
  const { user } = useUser();
  const { t } = useTranslation();

  const menus_list = [
    {
      key: 'menu_tasting_session',
      path: [
        '/',
        '/tasting_samples',
        '/tasting_voting',
        '/tasting_review',
        '/tasting_edit_voting',
        '/tasting_discussion',
        '/detail_discussion',
        '/tasting_ok_nok',
        '/tasting_discussion_ok_nok',
        '/tasting_threshold',
      ],
    },
    {
      key: 'process_check',
      privilege: 'PROCESS_CHECK',
      path: ['/list_ProcessCheck', '/edit_ProcessCheck'],
    },
    {
      key: 'menu_panel_management',
      submenu: true,
      path: [
        '/list_rounds',
        '/edit_round',
        '/new_round',
        '/list_samples',
        '/edit_sample',
        '/new_sample',
        '/list_brewery_production_suspension',
        '/edit_brewery_production_suspension',
        '/list_groups',
        '/edit_group',
        '/new_group',
      ],
    },
    {
      key: 'menu_product_management',
      submenu: true,
      path: [
        '/list_styles',
        '/edit_style',
        '/list_brands',
        '/edit_brand',
        '/list_packages',
        '/edit_packages',
        '/list_malt_variety',
        '/list_kpi_brands',
        '/edit_kpi_brand',
        '/new_kpi_brand',
        '/details_kpi_brand',
      ],
    },
    {
      key: 'menu_attributes_management',
      submenu: true,
      path: [
        '/list_intensities',
        '/edit_intensity',
        '/list_categories',
        '/edit_flavor_category',
        '/list_attributes',
        '/edit_attribute',
      ],
    },
    {
      key: 'user_list_users',
      privilege: 'USER_MANAGEMENT_LIST',
      path: ['/list_user', '/edit_user'],
    },
    {
      key: 'report_list_report',
      privilege: 'REPORT_LIST',
      path: ['/list_reports'],
    },
    {
      key: 'email_distribution',
      privilege: 'EMAIL_MANAGEMENT_LIST',
      path: ['/list_email_configuration'],
    },
    {
      key: 'data_analysis_sip',
      privilege: 'REPORT_LIST',
      path: ['/data_analysis_sip'],
    },
    {
      key: 'list_breathalyzer',
      path: ['/list_breathalyzer'],
    },
    {
      key: 'update_sprint_patch_notes',
      privilege: 'UPDATE_SPRINT_PATCH_NOTES',
      path: ['/sprint_patch_notes'],
    },
  ];
  const [menus, setMenus] = useState(menus_list);
  const [url, seturl] = useState();

  const handleActiveMenu = menuKey => {
    sessionStorage.setItem('menu_active', menuKey);
    setMenus(menus_list);
  };

  const handleActiveTab = tabKey => {
    checkVersion();
    sessionStorage.setItem('tab_active', tabKey);
    setMenus(menus_list);
  };

  const popstate = () => {
    const urls = window.location.pathname;
    sessionStorage.setItem('menu_active', urls);
    sessionStorage.setItem('tab_active', urls);
    seturl(urls);
  };

  const pops = () => {
    if (window.onpopstate) {
      window.onpopstate = null;
    }
    window.onpopstate = popstate;
  };

  useEffect(() => {
    pops();
  }, [url]);

  return user.showMenu ? (
    <div className={menuStyle.appMenu} >
      <ul className={menuStyle.appNav} >
        {menus.map(menu =>
          //CHECK IF USER HAS PRIVILEGE TO USE PAGE
          !menu.privilege ||
            (user?.arr_privileges &&
              (user?.arr_privileges.includes(menu.privilege) ||
                user?.arr_privileges.filter(privilege =>
                  props.modules
                    .filter(module => module.routeModule.subMenu === menu.key)
                    .map(item => item.routeModule.privilege)
                    .includes(privilege),
                ).length > 0)) ? (
            <li
              key={menu.key + '-father'}
              className={
                menu.path.indexOf(sessionStorage.getItem('menu_active')) !== -1
                  ? menuStyle.active
                  : ''
              }
              onClick={() => handleActiveMenu(menu.path[0])}
            >
              {menu.submenu &&
                !(
                  user?.arr_privileges.length === 1 &&
                  user?.arr_privileges[0] === 'PROCESS_CHECK'
                ) ? (
                <div>
                  <label>
                    {t(menu.key)} <ChevronRight size={18} />
                  </label>
                  <ul className={menuStyle.appSubmenu}>
                    {props.modules
                      .filter(module => module.routeModule.subMenu === menu.key)
                      .map(filterModule =>
                        //CHECK IF USER HAS PRIVILEGE TO USE PAGE
                        user?.arr_privileges &&
                          user?.arr_privileges.includes(
                            filterModule.routeModule.privilege,
                          ) ? (
                          <li
                            key={filterModule.routeModule.key}
                            className={
                              sessionStorage.getItem('tab_active') ===
                                filterModule.routeModule.path ||
                                filterModule.routeModule.routeComponents.some(
                                  item => {
                                    return (
                                      item.path ===
                                      sessionStorage.getItem('tab_active')
                                    );
                                  },
                                )
                                ? menuStyle.active
                                : ''
                            }
                            id={`${filterModule.routeModule.path.slice(1)}`}
                          >
                            <Link
                              key={filterModule.routeModule.key}
                              to={filterModule.routeModule.path}
                              onClick={() =>
                                handleActiveTab(filterModule.routeModule.path)
                              }
                            >
                              {t(filterModule.routeModule.key)}
                            </Link>
                          </li>
                        ) : (
                          ''
                        ),
                      )}
                  </ul>
                </div>
              ) : (
                props.modules
                  .filter(module => module.routeModule.key === menu.key)
                  .map(filterModule =>
                    //CHECK IF USER HAS PRIVILEGE TO USE PAGE
                    !filterModule.privilege ||
                      (user?.arr_privileges &&
                        user?.arr_privileges.includes(
                          filterModule.routeModule.privilege,
                        )) ? (
                      user?.arr_privileges.length === 1 &&
                        user?.arr_privileges[0] === 'PROCESS_CHECK' ? (
                        filterModule.routeModule.key !==
                        'list_breathalyzer' && (
                          <Link
                            key={filterModule.routeModule.key}
                            to={filterModule.routeModule.path}
                            onClick={() =>
                              handleActiveTab(filterModule.routeModule.path)
                            }
                            id={`${filterModule.routeModule.path.slice(1)}`}
                          >
                            {t(filterModule.routeModule.key)}
                          </Link>
                        )
                      ) : (
                        <Link
                          key={filterModule.routeModule.key}
                          to={filterModule.routeModule.path}
                          onClick={() =>
                            handleActiveTab(filterModule.routeModule.path)
                          }
                          id={`${filterModule.routeModule.path.slice(1)}`}
                        >
                          {t(filterModule.routeModule.key)}
                        </Link>
                      )
                    ) : (
                      ''
                    ),
                  )
              )}
            </li>
          ) : (
            ''
          ),
        )}
      </ul>
    </div>
  ) : (
    ''
  );
};

Menu.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default Menu;
