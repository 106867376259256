import React, { useEffect, useState } from 'react';
import editRoundScheduleStyle from './editroundschedule.module.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Card,
  CardRow,
  DropdownField,
  DatePickerField,
  UploadButton,
  CardDocument,
  Button,
} from '../../../components';
import RoundService from '../../services/rounds/round.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { Lock, Unlock } from 'react-feather';
import SampleService from '../../services/samples/sample.service';
import { useProfile } from '../../../contexts/Profile';
import { useUser } from '../../../contexts/User';
import { IoEmit } from '../../../utils/socketIo';

const arr = [];
const deleteFiles = [];

const EditRoundSchedule = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [roundType, setRoundTypes] = useState();
  const roundService = new RoundService();
  const appService = new AppService();
  const samplesService = new SampleService();
  const [disabledSave, setDisabledSave] = useState(false);
  const [blockRoundPrivilege, setBlockRoundPrivilege] = useState(null);
  const [idSite, setIdSite] = useState(props.round.uuid_site);
  const [idCountry, setIdCountry] = useState(props.round.uuid_country);
  const [idZone, setIdZone] = useState(props.round.uuid_zone);
  const [roundName, setRoundName] = useState(props.round.str_name_round);
  const [kpisSampleCodes, setKpisSampleCodes] = useState([]);
  const [isLoadingKpisSampleCodes, setIsLoadingKpisSampleCodes] =
    useState(false);

  function handleChangeRoundName (evt) {
    setRoundName(evt.target.value);
    handleChange(evt);
  }
  const { profileLevels } = useProfile();
  const { user } = useUser();

  useEffect(() => {
    if (props.isNewRound && !props.round.uuid_site) {
      refabc();
    }
  }, []);

  const refabc = () => {
    setIdZone(user.uuid_zone);
    setIdCountry(user.uuid_country);
    setIdSite(user.uuid_site);
    if (!props.roundId) {
      props.setRound(prevState => {
        if (idCountry !== undefined) {
          delete prevState.uuid_country;
          delete prevState.str_country_name;
        }

        if (idZone !== undefined) {
          delete prevState.uuid_zone;
          delete prevState.str_zone_name;
        }

        delete prevState.uuid_site;
        delete prevState.str_site_name;

        if (!!user.uuid_site) {
          getKPICodesByPrivilege(user.uuid_site);
        }
        return {
          ...prevState,
          uuid_site: user.uuid_site,
          uuid_country: user.uuid_country,
          uuid_zone: user.uuid_zone,
        };
      });
    }
  };

  useEffect(() => {
    if (!!props.round.uuid_round) {
      getKPICodesByPrivilege(props.round.uuid_site);
    }
  }, [profileLevels]);

  useEffect(() => {
    roundService.listRoundTypes().then(
      res => {
        if (res.type === 'success' && res.results) {
          setRoundTypes(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    for (var i = arr.length; i > 0; i--) {
      arr.pop();
    }

    for (var j = deleteFiles.length; j > 0; j--) {
      deleteFiles.pop();
    }

    appService.listPrivileges('ROUND_BLOCK_RESULTS').then(
      res => {
        if (res.type === 'success' && res.results) {
          setBlockRoundPrivilege(res.results[0]);
        } else {
          //console.log(res);
        }
      },
      error => {
        //console.log(error);
      },
    );

    getKPICodesByPrivilege(props.round.uuid_site);
    setRoundName(props.round.str_name_round);
  }, []);

  const handleChange = evt => {
    const value = evt.target.value;
    let arrDocs = [];
    let datFinish = {};
    if (evt.target.name === 'dat_start' && props.round.dat_finish) {
      if (new Date(value) >= new Date(props.round.dat_finish)) {
        alert(t('round_schedule_start_finish_date'));
        return false;
      }
    }

    if (evt.target.name === 'dat_finish' && evt.target.value) {
      if (!props.round.dat_start) {
        alert(t('round_schedule_finish_date_no_start_date'));
        return false;
      } else if (new Date(props.round.dat_start) > new Date(value)) {
        props.setRound({
          ...props.round,
          dat_finish: props.round.dat_start,
        });
        alert(t('round_schedule_start_finish_date'));
        return false;
      }
      if (new Date(value) <= new Date()) {
        props.setRound({
          ...props.round,
          dat_finish: null,
        });
        alert(t('the_end_time_is_greater_than_the_current_time'));
        return;
      }
    }

    if (
      evt.target.name === 'uuid_site' &&
      props.privilege.uuid_site_user !== value
    ) {
      if (!window.confirm(t('round_create_site_message'))) {
        return;
      }
    }

    if (evt.target.name === 'str_name_round' && evt.target.value.length > 39) {
      alert(t('round_schedule_maximum_length'));
      return false;
    }

    if (evt.target.name === 'uuid_round_type') {
      if (evt.target.value === '43b11c76-043c-11eb-b18d-026fdc1cc8f2') {
        props.round.bol_ranked = 1;
      } else {
        props.round.bol_ranked = 0;
      }

      if (evt.target.value != '4ed984d9-021d-4d06-a992-991a24b3d7ce') {
        props.round.uuid_kpi_codes = null;
      }
    }

    if (evt.target.name === 'str_zip_documents') {
      let dataFilter = _.filter(arr, { str_zip_document: value });
      let dataFilterSample = _.filter(props.round.str_zip_documents, {
        str_zip_document: value,
      });

      if (dataFilter.length === 0 && dataFilterSample.length === 0) {
        arr.push({ str_zip_document: value, uuid_document: '' });
        arrDocs = _.union(props.round.str_zip_documents, arr);
      }

      const upload = new Promise(async (resolve, reject) => {
        appService
          .updateDocument({
            uuid_sample: null,
            uuid_round: props.round.uuid_round,
            str_zip_document: [{ str_zip_document: value, uuid_document: '' }],
          })
          .then(
            res => {
              resolve(true);
            },
            error => {
              //console.log(error);
              reject(true);
            },
          );
      });
      upload.then(
        res => { },
        error => { },
      );
    }

    let data =
      evt.target.name === 'bol_hide_sample_details'
        ? { [evt.target.name]: value === 'NO' ? 0 : 1 }
        : evt.target.name === 'bol_is_countdown'
          ? { [evt.target.name]: value === 'NO' ? 0 : 1 }
          : evt.target.name === 'bol_tvs'
            ? { [evt.target.name]: value === 'NO' ? false : true }
            : evt.target.name === 'bol_score'
              ? { [evt.target.name]: value === 'NO' ? 0 : 1 }
              : evt.target.name !== 'str_zip_documents'
                ? evt.target.name === 'dat_start' || evt.target.name === 'dat_finish'
                  ? { [evt.target.name]: value }
                  : { [evt.target.name]: value }
                : { [evt.target.name]: arrDocs };

    props.setRound(prevState => {
      return { ...prevState, ...data, ...datFinish };
    });
  };

  useEffect(() => {
    let { bol_is_countdown, uuid_round_type, dat_finish } = props.round;
    const bolTrainingType =
      uuid_round_type === '0d55fca0-e7a2-11ec-a8bc-000000000000' ||
      uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000' ||
      uuid_round_type === 'e4454b00-6950-11ed-bb18-000000000000';
    if (bol_is_countdown && dat_finish && bolTrainingType) {
      IoEmit('changeFinishDate', props.round);
    }
  }, [props.round])

  const handleChangeResultsBlock = () => {
    props.setRound(prevState => {
      return {
        ...prevState,
        bol_block_results: !props.round.bol_block_results,
      };
    });
  };

  const handleRemove = (evt, index) => {
    appService.removeDocument(evt.uuid_document).then(res => {
      props.round.str_zip_documents.filter(item => {
        return item.uuid_document !== evt.uuid_document;
      });
    });
    deleteFiles.push({ index: index, evt: evt });

    let data = {
      str_zip_documents: props.round.str_zip_documents.filter(
        (doc, i) => i !== index,
      ),
    };
    for (var i = arr.length; i > 0; i--) {
      arr.pop();
    }
    arr.pop();

    props.setRound(prevState => {
      return { ...prevState, ...data };
    });
  };

  const handleSave = () => {
    history.goBack();
  };

  const getKPICodesByPrivilege = async uuidSite => {
    if (!!uuidSite) {
      setIsLoadingKpisSampleCodes(true);
      await samplesService.getAllKpiRoundCodes().then(res => {
        if (res.type === 'success' && res.results) {
          const filteredPrivileges = !!profileLevels
            ? profileLevels
              .filter(
                profile =>
                  profile.uuid_zone == idZone ||
                  profile.uuid_zone == props.round.uuid_zone,
              )
              .filter(
                profile =>
                  profile.uuid_country == idCountry ||
                  profile.uuid_country == props.round.uuid_country ||
                  !profile.uuid_country,
              )
              .filter(
                profile =>
                  profile.uuid_site == uuidSite ||
                  profile.uuid_site == props.round.uuid_site ||
                  !profile.uuid_site,
              )
            : [];

          let allPrivileges = [];

          filteredPrivileges.forEach(privilege => {
            allPrivileges = [...allPrivileges, ...privilege.arr_privileges];
          });

          allPrivileges = allPrivileges.filter(
            privilege => privilege.str_name.slice(0, 3) == 'KPI',
          );

          setOnlyPermitedKPIS(allPrivileges, res.results);
          setIsLoadingKpisSampleCodes(false);
        } else {
          window.alert(res.message);
          setIsLoadingKpisSampleCodes(false);
        }
      });
    } else {
      setKpisSampleCodes([]);
    }
  };

  const setOnlyPermitedKPIS = (profilePrivileges, allKpiCodes) => {
    const userKPIPrivilegesCodes = profilePrivileges.map(privilege =>
      privilege.str_name.slice(6, 14),
    );
    const allowedKPIS = allKpiCodes.filter(kpisCode =>
      userKPIPrivilegesCodes.find(code => kpisCode.str_code == code),
    );

    setKpisSampleCodes(allowedKPIS);
  };

  useEffect(() => {
    const str_zone_name = props.privilege.arr_zones?.find(item => item.uuid_zone === props.round.uuid_zone)?.str_name ?? '';
    const str_country_name = props.privilege.arr_countries?.find(item => item.uuid_country === props.round.uuid_country)?.str_name ?? '';
    const str_site_name = props.privilege.arr_sites?.find(item => item.uuid_site === props.round.uuid_site)?.str_name ?? '';
    props.setRound(prevState => {
      return { ...prevState, str_zone_name, str_country_name, str_site_name };
    });

  }, [props.round.uuid_site])

  return (
    <Card>
      <CardRow justifyContent="flex-end" flexWrap="wrap" margin="10">
        <Button
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 32,
            width: 85,
            backgroundColor: props.round.bol_block_results ? '#b11f24' : null,
          }}
          green
          onClick={handleChangeResultsBlock}
          disabled={
            blockRoundPrivilege
              ? !blockRoundPrivilege.arr_sites?.some(
                x => x.uuid_site === props.round.uuid_site,
              )
              : true
          }
        >
          {props.round.bol_block_results ? (
            <Lock size={20} />
          ) : (
            <Unlock size={20} />
          )}
        </Button>
        <HelpCircle
          className={editRoundScheduleStyle.helpCircle}
          data-tip={t('round_block_results_tooltip')}
          data-for="round_block_results_tooltip"
        />
        <ReactTooltip
          effect="solid"
          place="top"
          type="warning"
          id="round_block_results_tooltip"
          className={editRoundScheduleStyle.tooltip}
        />
      </CardRow>
      <CardRow flexWrap="wrap">
        <DropdownField
          name="uuid_zone"
          options={props.privilege.arr_zones ? props.privilege.arr_zones : []}
          valueField="uuid_zone"
          labelField="str_name"
          label={t('common_zone')}
          margin="10px 10px 0px 0px"
          value={idZone !== undefined ? idZone : props.round.uuid_zone}
          onChange={value => {
            props.round.uuid_country = null;
            props.round.uuid_site = null;
            props.round.uuid_kpi_codes = null;

            setIdCountry(null);
            setIdSite(null);
            setIdZone(value.target.value);
          }}
          isRequired={true}
        />
        <DropdownField
          name="uuid_country"
          options={
            props.privilege.arr_countries &&
              (props.round.uuid_zone || idZone !== undefined)
              ? props.privilege.arr_countries.filter(country => {
                const idZoneAux =
                  idZone !== undefined ? idZone : props.round.uuid_zone;
                return country.uuid_zone === idZoneAux;
              })
              : []
          }
          valueField="uuid_country"
          labelField="str_name"
          label={`${t('common_country')}`}
          margin="10px 10px 0px 0px"
          value={idCountry !== undefined ? idCountry : props.round.uuid_country}
          onChange={value => {
            props.round.uuid_site = null;
            props.round.uuid_kpi_codes = null;

            setIdSite(null);
            setIdCountry(value.target.value);
          }}
          isRequired={true}
        />

        <DropdownField
          name="uuid_site"
          options={
            props.privilege.arr_sites &&
              (props.round.uuid_country || idCountry !== undefined)
              ? props.privilege.arr_sites.filter(site => {
                const idCountryAux =
                  idCountry !== undefined
                    ? idCountry
                    : props.round.uuid_country;
                return site.uuid_country === idCountryAux;
              })
              : []
          }
          valueField="uuid_site"
          labelField="str_name"
          label={`${t('common_site')}`}
          margin="10px 10px 0px 0px"
          value={idSite !== undefined ? idSite : props.round.uuid_site}
          onChange={value => {
            const valueCountry =
              idCountry !== undefined ? idCountry : props.round.uuid_country;

            const siteLenght = props.privilege.arr_sites.filter(site => {
              return (
                site.uuid_country === valueCountry &&
                value.target.value === site.uuid_site
              );
            });

            if (siteLenght.length > 0) {
              setIdSite(value.target.value);
              props.setRound(prevState => {
                if (idCountry !== undefined) {
                  delete prevState.uuid_country;
                  delete prevState.str_country_name;
                }

                if (idZone !== undefined) {
                  delete prevState.uuid_zone;
                  delete prevState.str_zone_name;
                }

                delete prevState.uuid_site;
                delete prevState.str_site_name;

                if (!!value.target.value) {
                  getKPICodesByPrivilege(value.target.value);
                }

                return {
                  ...prevState,
                  uuid_site: value.target.value,
                  uuid_country:
                    idCountry !== undefined
                      ? idCountry
                      : props.round.uuid_country,
                  uuid_zone:
                    idZone !== undefined ? idZone : props.round.uuid_zone,
                };
              });
            }
          }}
          isRequired={true}
        />
      </CardRow>
      <CardRow flexWrap="wrap">
        <DatePickerField
          name="dat_start"
          type={'datetime-local'}
          label={t('common_start_date')}
          margin="10px 10px 0px 0px"
          value={props.round.dat_start}
          onChange={handleChange}
          isRequired={true}
        />
        <DatePickerField
          name="dat_finish"
          type={'datetime-local'}
          label={t('common_finish_date')}
          margin="10px 10px 0px 0px"
          value={props.round.dat_finish}
          onChange={handleChange}
          minDate={props.round.dat_start ? props.round.dat_start : null}
          isRequired={true}
        />
        <DropdownField
          name="uuid_round_type"
          options={roundType}
          valueField="uuid_round_types"
          labelField="str_name"
          label={t('common_round_type')}
          margin="10px 10px 0px 0px"
          value={props.round.uuid_round_type}
          onChange={handleChange}
          bolOrder={false}
          isRequired={true}
        />
      </CardRow>
      <CardRow flexWrap="wrap">
        {props.round.uuid_round_type ===
          '0d55fca0-e7a2-11ec-a8bc-000000000000' ||
          props.round.uuid_round_type ===
          '02095a80-6cc4-11ec-8d68-000000000000' ||
          props.round.uuid_round_type ===
          'e4454b00-6950-11ed-bb18-000000000000' ? (
          <DropdownField
            options={[{ str_name: 'NO' }, { str_name: 'YES' }]}
            disabled={
              typeof props.round.arr_status === 'object' &&
              props.round.arr_status.length >= 4 &&
              props.round.arr_status[3] === 'IN_PROGRESS'
            }
            name="bol_is_countdown"
            labelField="str_name"
            valueField="str_name"
            margin="10px 10px 0px 0px"
            label={t('countdown')}
            value={props.round.bol_is_countdown ? 'YES' : 'NO'}
            onChange={handleChange}
            show={false}
            hideRemoveButton
          />
        ) : null}
        {props.round.uuid_round_type ===
          '0d55fca0-e7a2-11ec-a8bc-000000000000' ? (
          <DropdownField
            options={[{ str_name: 'NO' }, { str_name: 'YES' }]}
            disabled={
              typeof props.round.arr_status === 'object' &&
              props.round.arr_status.length >= 4 &&
              props.round.arr_status[3] === 'IN_PROGRESS'
            }
            name="bol_tvs"
            labelField="str_name"
            valueField="str_name"
            margin="10px 10px 0px 0px"
            label={'TVS'}
            value={props.round.bol_tvs ? 'YES' : 'NO'}
            onChange={handleChange}
            show={false}
            hideRemoveButton
          />
        ) : null}
        {(props.round.uuid_round_type ===
          'b8082a00-e1d1-11ec-97a3-000000000000' ||
          props.round.uuid_round_type ===
          '43b11c76-043c-11eb-b18d-026fdc1cc8f2' ||
          props.round.uuid_round_type ===
          '4ed984d9-021d-4d06-a992-991a24b3d7ce' ||
          props.round.uuid_round_type ===
          'be229b18-2eb4-11eb-ba1d-0229531a6f14') && (
            <DropdownField
              name="bol_hide_sample_details"
              options={[{ str_name: 'NO' }, { str_name: 'YES' }]}
              valueField="str_name"
              margin="10px 10px 0px 0px"
              hideRemoveButton
              labelField="str_name"
              label={t('hide_sample_details')}
              onChange={handleChange}
              value={props.round.bol_hide_sample_details ? 'YES' : 'NO'}
              toolTip={
                props.round.uuid_round_type ===
                  'be229b18-2eb4-11eb-ba1d-0229531a6f14' ?
                  t('data_will_be_hidden_for_ok_nok')
                  : t('data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id')
              }
              disabled={
                typeof props.round.arr_status === 'object' &&
                props.round.arr_status.length >= 4 &&
                props.round.arr_status[3] === 'IN_PROGRESS'
              }
            />
          )}
        {props.round.uuid_round_type ===
          'be229b18-2eb4-11eb-ba1d-0229531a6f14' && (
            <DropdownField
              name="bol_score"
              options={[{ str_name: 'NO' }, { str_name: 'YES' }]}
              valueField="str_name"
              margin="10px 10px 0px 0px"
              labelField="str_name"
              label={t('scoring')}
              onChange={handleChange}
              value={props.round.bol_score ? 'YES' : 'NO'}
              disabled={
                typeof props.round.arr_status === 'object' &&
                props.round.arr_status.length >= 4 &&
                props.round.arr_status[3] === 'IN_PROGRESS'
              }
              hideRemoveButton
            />
          )}
        {(props.round.uuid_round_type ===
          '0d55fca0-e7a2-11ec-a8bc-000000000000' ||
          props.round.uuid_round_type ===
          'be229b18-2eb4-11eb-ba1d-0229531a6f14') ? null : (
          <div className={editRoundScheduleStyle.creatRoundDiv}></div>
        )}
        <div className={editRoundScheduleStyle.creatRoundDiv}></div>
      </CardRow>

      <CardRow flexWrap="wrap">
        <TextField
          name="int_round"
          label={t('common_round_id')}
          margin="10px 10px 0px 0px"
          value={props.roundId}
          disabled
        />
        <TextField
          name="str_name_round"
          label={t('common_name')}
          margin="10px 10px 0px 0px"
          value={
            roundName !== undefined ? roundName : props.round.str_name_round
          }
          onChange={handleChangeRoundName}
          maxLength="40"
        />
      </CardRow>
      <CardRow display="inline-flex" margin="10" flexWrap="wrap">
        {props.round.str_zip_documents
          ? props.round.str_zip_documents.map((doc, i) => (
            <CardDocument
              name="str_zip_documents"
              src={doc.str_zip_document}
              onRemove={() => handleRemove(doc, i)}
              margin="10px 0px"
              size={80}
              width="100px"
            />
          ))
          : null}
      </CardRow>
      <CardRow flexWrap="wrap">
        <React.Fragment>
          <div className={editRoundScheduleStyle.uploadCard}>
            <UploadButton
              name="str_zip_documents"
              label={t('sample_upload_document')}
              onUpload={handleChange}
              multiple={true}
              allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
              maxSize={5242880}
              disabled={!props.round.uuid_site}
            />
            <HelpCircle
              className={editRoundScheduleStyle.helpCircle}
              data-tip={`${t(
                'sample_upload_message',
              )} .pdf, .jpg, .jpeg, .png. (5mb)`}
              data-for="sample_upload_document"
            />
            <ReactTooltip
              id="sample_upload_document"
              effect="solid"
              place="top"
              type="warning"
              className={editRoundScheduleStyle.tooltip}
            />
          </div>
        </React.Fragment>
        <React.Fragment>
          {props.round.uuid_round_type ==
            '4ed984d9-021d-4d06-a992-991a24b3d7ce' ? (
            <div className={editRoundScheduleStyle.kpiSamplesCodes}>
              <DropdownField
                name="uuid_kpi_codes"
                options={kpisSampleCodes}
                isLoading={isLoadingKpisSampleCodes}
                valueField="uuid_kpi_codes"
                labelField="str_name"
                label={'KPI Code for KPI samples'}
                margin="10px 10px 0px 0px"
                value={props.round.uuid_kpi_codes}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      </CardRow>
      <CardRow justifyContent="flex-end" flexWrap="wrap">
        <Button
          label={t('common_cancel')}
          onClick={() => {
            history.goBack();
          }}
          neutral
          margin="10px 10px 0px 0px"
        />
        <Button
          label={t('common_save')}
          onClick={handleSave}
          confirm
          margin="10px 10px 0px 0px"
          disabled={
            disabledSave ||
            !props.round.uuid_site ||
            !props.round.dat_start ||
            !props.round.dat_finish
          }
        />
      </CardRow>
    </Card>
  );
};

EditRoundSchedule.propTypes = {
  privilege: PropTypes.any,
  roundTypes: PropTypes.any,
  round: PropTypes.any,
  setRound: PropTypes.any,
};

export default EditRoundSchedule;

