import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Label } from '../';
import textFieldStyle from './textField.module.scss';
import alertCircleImg from '../../assets/alert_circle.png';

const TextField = props => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(props.disabled ? 'disabled' : false);
  }, [props.disabled]);

  const getType = () => {
    if (props.text) {
      return 'text';
    } else if (props.number) {
      return 'number';
    } else if (props.password) {
      return 'password';
    } else {
      return 'text';
    }
  };

  const handleOnKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        if (props.onHintEnter) {
          props.onHintEnter(event.target.value);
        }
        break;
      default:
        break;
    }
  };

  const handleChange = event => {
    if (props.formatValue) {
      event.target.value = props.formatValue(event.target.value);
    }

    if (props.name === 'alcohol_concentration') {
      var num = event.target.value;
      const nowText = !isNaN(Number(num)) || num === '' ? num : props.value;
      event.target.value = nowText;
    }

    if (props.name === 'str_number_days_over_aged') {
      var num = event.target.value;
      const nowText =
        (!isNaN(Number(num)) && num > 0) || num === '' ? num : props.value;
      event.target.value = nowText ? nowText : '';
    }

    if (
      props.name === 'flt_concentration' ||
      props.name === 'flt_set3_concentration'
    ) {
      var num = event.target.value;
      const nowText =
        !isNaN(Number(num)) &&
          num < 1000 &&
          (String(num).indexOf('.') !== -1
            ? String(num).split('.')[1].length <= 3
            : true)
          ? num
          : props.value;
      event.target.value = nowText;
    }
    if (
      props.name === 'concentration' &&
      event.target.value.indexOf('.') > -1
    ) {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.indexOf('.') + 4,
      );
    }
    if (props.name === 'concentration_flavor') {
      var num = event.target.value;
      const newValue = isNaN(Number(num)) ? disabled : num;
      event.target.value = newValue;
    }

    if (props.name === 'flt_incremental_step') {
      var num = event.target.value;
      if (Number(num) > 3) {
        num = event.target.value.substr(0, event.target.value.length - 1);
      }
      const nowText =
        !isNaN(Number(num)) &&
          num < 1000 &&
          (String(num).indexOf('.') !== -1
            ? String(num).split('.')[1].length <= 1
            : true)
          ? num
          : props.value;
      event.target.value = nowText;
    }

    props.onChange(
      event,
      props.name === 'concentration_flavor' ? props.index : props.id,
    );
  };

  return (
    <div
      className={
        props.withouBorder
          ? textFieldStyle.textFieldContainerWithouBorder
          : textFieldStyle.textFieldContainer
      }
      style={{
        margin: props.margin ? props.margin : '0',
        flex: props.flex ? props.flex : 1,
        maxWidth: props.maxWidth,
        opacity: props.opacity,
        ...props.boxStyle,
      }}
    >
      <div className={textFieldStyle.labelTextBox}>
        <Label
          style={props.labelStyle}
          label={props.label ? props.label : ''}
          required={props.isRequired ? props.isRequired : ''}
        />
        {props.isIconRequired && !props.value && (
          <img
            className={textFieldStyle.isIconRequired}
            src={alertCircleImg}
            alt="beer"
          />
        )}
      </div>
      <input
        data-testid="textFieldInput"
        style={{
          padding: props.padding ? props.padding : '11px 5px',
          fontSize: '0.9em',
          borderColor: props.isIconRequired && !props.value ? '#B11F24' : '',
          ...props.style,
        }}
        ref={props.focus}
        autoComplete="off"
        name={props.name}
        type={getType()}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={handleChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        placeholder={props.placeholder ? props.placeholder : ''}
        disabled={disabled}
        autoFocus={props.autoFocus ? true : false}
        onKeyDown={e => handleOnKeyDown(e)}
        maxLength={props.maxLength || ''}
        min={props.min}
        step={props.step}
      />
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  text: PropTypes.bool,
  number: PropTypes.bool,
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  flex: PropTypes.string,
  withouBorder: PropTypes.bool,
  focus: PropTypes.any,
  onHintEnter: PropTypes.func,
  style: PropTypes.object,
  isRequired: PropTypes.bool,
  formatValue: PropTypes.func,
  index: PropTypes.any,
};

export default TextField;
