export default {
  sample_no_production_date_message: '必须选择生产日期',
  free_comment: '自定义评语',
  round_list_rounds: '品评计划',
  round_edit_round: '编辑品评',
  round_new_round: '新增品评',
  round_remove_round: '是否确定要删除该轮品评',
  round_schedule: '计划',
  round_tasters_unselect_all: '取消全选',
  round_tasters_select_all: '选择所有品评员',
  round_close: '结束品评',
  round_close_message: '您确定要结束这一轮品评吗',
  round_create_site_message: '您正在创建一个不属于您们工厂的品评！',
  round_closed_message: '无法编辑已结束的品评',
  round_status_created: '创建',
  round_status_samples: '样品',
  round_status_scheduled: '已计划',
  round_status_tasters: '品评员',
  round_status_in_progress: '进行中',
  round_status_finished: '已完成',
  round_status_closed: '已关闭',
  round_schedule_start_finish_date: '开始日期不能晚于结束日期！',
  round_schedule_finish_date_no_start_date: '您必须先选择一个开始日期！',
  round_disabled_discussion_tooltip: '您不能给未结束的品评添加评论',
  round_delete_other_users_rounds: '您没有权限去删除不是您创建的品评',
  round_randomize_samples_order_button: '随机',
  round_block_results_tooltip:
    '在报告页中禁止下载这一轮品评的报告。如果该按钮被禁用，则您无权在此位置锁定品评报告',
  round_block_results_confirm: '是否确认要禁止/取消禁止该轮品评报告的下载',
  round_block_results_no_privilege:
    '您无权从此位置禁止/取消禁止该轮品评报告的下载。',
  sample_list_samples: '样品',
  sample_edit_sample: '编辑样品',
  sample_new_sample: '新样品',
  sample_remove_sample: '移除样品',
  sample_upload_document: '上传文件',
  sample_download_documents: '下载文件',
  sample_tasted_dates: '品评日期',
  sample_upload_message: '要上传多个文档，请将它们添加到',
  sample_not_for_kpi: '不适用于KPI',
  sample_package: '包装',
  sample_volume: '容量',
  sample_production_date: '生产日期',
  'sample_no_production_date_message ': '生产日期为必填项',
  sample_expire_date: '过期时间',
  sample_trial_identifier: '测试识别码',
  sample_project_name: '项目名称',
  sample_linked: '链接到新一轮',
  sample_expire_bigger_than_production: '生产日期不能晚于过期时间！',
  sample_no_site_message: '必须选择区域、国家和工厂来创建样品。',
  sample_no_brand_message: '必须选择一个品牌。',
  sample_duplicated_kpi_message:
    '您选择的月份、品牌和工厂已经有KPI，您将无法保存',
  sample_duplicated_project_message:
    '已经有一个带有此项目名称、工厂和品牌的测试识别码的样品，您将无法保存',
  sample_project_no_name_or_trial: '项目名称和测试识别码为必填项',
  sample_production_to_project_confirm_message:
    '您正在将此样品的类型从产品更改为项目，你想继续吗？',
  sample_project_to_production_confirm_message:
    '您正在将此样品的类型从项目更改为产品，你想继续吗？',
  sample_no_volume_message: '创建/编辑样品时，必须注明包装类型和容量。',
  sample_id_code_message: '请附上完整的批号',
  sample_show_all_samples_hint:
    '显示从今天起六个月内创建的样品。如果还想查看更早的样品，请选中此框',
  sample_show_all_samples_label: '展示所有样品',
  package_duplicate: '已经有一个同名的包装类型',
  common_production: '生产',
  common_project: '项目',
  group_list_groups: '用户组',
  group_edit_group: '编辑用户组',
  group_new_group: '新增用户组',
  group_remove_group: ' 删除用户组',
  group_close_modal: '关闭',
  group_duplicated_user: '此用户已被添加到此组！',
  group_no_name_message: '必须输入组名',
  group_no_zone_message: '必须选择一个大区',
  group_no_users_message: '必须至少选择一名品评员',
  family_list_families: '类型',
  family_edit_family: '编辑类型',
  family_new_family: '新类型',
  family_remove_family: '删除类型',
  family_has_no_english_name: '注意：类型名称必须是英文的',
  brand_list_brands: '品牌',
  brand_edit_brand: '编辑品牌',
  brand_new_brand: '新品牌',
  brand_remove_brand: '删除品牌',
  brand_profile_picture: '上传风味图谱',
  brand_upload_brand_logo: '上传品牌标识',
  brand_duplicate: '警告：已有一种啤酒使用这个名字',
  brand_has_no_english_name: '您必须填写一个品牌名称和与之对应的风味图谱',
  brand_upload_help_message: '仅限PNG和JPG文件',
  brand_has_no_product_type: '您必须选择一种产品类型',
  package_list_packages: '包装',
  package_edit_package: '编辑包装',
  package_new_package: '新包装',
  package_remove_package: '删除包装',
  package_has_no_english_name: '注意：包装名称必须为英文',
  package_volumes: '容量',
  package_new_volume: '新的容量',
  package_remove_volume: '删除容量',
  degree_list_degrees: '程度',
  degree_edit_degree: '编辑程度',
  degree_new_degree: '新增程度',
  degree_remove_degree: '去除程度',
  category_list_categories: '类别',
  category_edit_category: '编辑风味类别',
  category_new_category: '新风味类别',
  category_remove_category: '删除风味类别',
  comment_list_comments: '风味物质属性',
  comment_edit_comment: '编辑风味物质属性',
  comment_new_comment: '新风味物质属性',
  comment_remove_comment: '移除风味评语属性',
  user_list_users: '用户',
  user_edit_user: '编辑用户',
  user_new_user: '新增 ABI 承包商用户',
  user_export_users: '导出用户',
  user_list_profiles: '角色',
  user_profile_name: '角色名称',
  user_profile_select: '选择一个文件',
  user_profile_remove: '删除文件',
  user_profile_save: '保存文件',
  user_remove_user: '删除用户',
  user_use_terms: '批准的使用条款',
  report_list_report: '报告',
  report_end_date: '结束日期',
  common_privileges: '权限',
  common_abi_name: 'ABI名称',
  common_id: 'ID',
  common_ranked: '排名',
  common_week: '周',
  common_weeks: '周',
  common_days: '天',
  common_category: '风味类别',
  common_category_sample: '类别',
  common_degree: '程度',
  common_comment: '风味物质属性',
  common_status: '状态',
  common_zone: '大区',
  common_country: '国家',
  common_site: '工厂',
  common_start_date: '计划开始时间',
  common_finish_date: '计划结束时间',
  common_round_type: '测试类型',
  common_round: '品评计划',
  common_round_id: '品评计划ID',
  common_tasters: '品评员',
  common_taster: '品评员',
  common_sample_performance: '样品信息',
  common_taste: '品评',
  common_key_taster: '关键品评员',
  common_sample: '样品',
  common_samples: '样品',
  common_sample_type: '样品类型',
  common_family: '类型',
  common_brand: '品牌',
  common_product_type: '产品类型',
  common_temperature: '储存条件',
  common_storage_times: '储存时间',
  common_sku: 'SKU',
  common_batch: '批次号',
  common_kpi_month: '关键绩效指标月',
  common_logo: '标识',
  common_name: '名称',
  common_notes: '备注',
  common_email: '电子邮件',
  common_language: '语言',
  common_terms_of_use: '使用条款',
  common_new: '新的',
  common_profile: '权限',
  common_filter: '筛选用户',
  common_download: '下载',
  common_save: '保存',
  common_cancel: '取消',
  common_search_users: '搜索用户',
  common_search_group: '搜索用户组',
  common_search_taster: '搜索品评员',
  common_no_data: '没有数据显示',
  common_loading: '加载',
  common_cannot_be_empty: '{{field}}不能为空',
  common_select_option: '选择选项···',
  common_per_page: '每页行数',
  common_attributes: '属性',
  common_not_started: '未开始',
  common_in_progress: '进行中',
  common_finished: '已完成',
  common_start: '开始',
  common_detail: '详情',
  common_position: '样品顺序',
  common_average_key_score: '关键平均分',
  common_score: '分数',
  common_score_scale: '分数表',
  common_intensity: '程度',
  common_report_not_have_data: '选定的品评没有品评结果',
  month_0: '一月',
  month_1: '二月',
  month_2: '三月',
  month_3: '四月',
  month_4: '五月',
  month_5: '六月',
  month_6: '七月',
  month_7: '八月',
  month_8: '九月',
  month_9: '十月',
  month_10: '十一月',
  month_11: '十二月',
  dropdown_change_picture: '更换照片',
  dropdown_language: '语言',
  dropdown_logout: '注销',
  round_not_started: '未开始',
  round_in_progress: '进行中',
  round_finished: '已完成',
  round_filter_start: '开始',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: '品评计划列表',
  menu_tasting_session: '我的品评',
  menu_panel_management: '品评管理',
  menu_process_management: '过程管理',
  menu_product_management: '产品管理',
  menu_attributes_management: '风味物质属性',
  tasting_finish_sample: '完成品评',
  tasting_comments: '评语',
  tasting_beer_profile: '风味图谱',
  tasting_need_select_score_comments: '您必须添加分数和至少一条评论才能继续',
  tasting_finish_taste: '结束品评',
  common_no_comments: '没有评论',
  review_submit_title: '您确定您准备好提交结果了吗？',
  review_submit_description: '稍后您将无法编辑',
  review_finish_taste: '结束品评',
  review_warning_sample_title: '您有未经品评的样品！',
  review_warning_sample_description: '您确定不品评它们，继续提交吗？',
  review_warning_sample_review: '回顾',
  review_warning_sample_ok: '继续',
  review_warning_tie_sample_title: '您有排名相同的样品！',
  review_warning_tie_sample_description: '请重新排名后再结束品评！',
  review_warning_tie_sample_review: '回顾',
  common_submit: '提交',
  common_discussion: '讨论',
  common_not_informed: '没有接到通知',
  discussion_order_number: '按样品顺序排序',
  discussion_order_brewery: '按工厂排序',
  discussion_order_ranking: '按排名排序',
  discussion_order_brand: '按品牌排序',
  discussion_order_score: '按分数排序',
  discussion_order_key_score: '按关键分数排序',
  discussion_order_overall_rank: '按综合排名得分排序',
  discussion_order_key_rank: '按关键排名排序',
  discussion_key_ranking: '关键排名',
  discussion_export_error: '文件无法保存！',
  discussion_ranking: '排名',
  discussion_overall_ranking: '综合排名',
  common_brewery: '工厂',
  common_trial_identifier: '测试ID',
  common_key_score: '关键分数',
  round_report: '导出到Excel',
  common_comments: '评论',
  message_you_cannot_delete_all_comments: '编辑品评结果时不能删除所有评论',
  common_message_empty_profile: '配置文件名称不允许为空',
  common_message_out_score_range: '分数超出范围',
  tasting_need_select_score: '您必须先填写一个分数',
  common_category_name: '类别',
  categories_new_category: '新类别',
  categories_remove_category: '是否确认要删除该类别',
  categories_list_categories: '类别',
  categories_list_all_categories: '所有类别',
  common_category_sample_name: '样品',
  categories_new_category_sample: '新样品',
  categories_remove_category_sample: '您真的想移除该样品吗',
  categories_list_categories_samples: '样品',
  tasting_help: '品评帮助',
  common_back: '返回',
  common_next: '下一页',
  common_sample_id: '样品ID',
  common_sample_date: '样品日期',
  common_duplicate: '复制',
  tasting_raw_materials: '原材料',
  tasting_example_sensory_risk: '主要感官风险示例：不符合预期口味',
  tasting_procedure: '过程',
  tasting_sample_preparation: '样品准备',
  tasting_help_comments: '评语',
  common_collection_point: '收集点',
  common_collection_date: '收集日期',
  common_brew_number: '啤酒编号/MES编号',
  common_need_explain_nok: '您必须为选择NOK的样品添加评语！',
  common_percent_ok: 'OK百分比',
  common_show_all: '全部展示',
  common_import: '导入',
  common_download_template: '下载模板',
  sample_import_message: 'xlsx',
  common_template: '模板',
  common_yes: '是',
  common_no: '否',
  common_need_volte_first: '您不能在没有评分的情况下为样品添加评语！',
  common_name_template: '模板名',
  interaction_log: 'Interaction Log',
  common_message_zone_empty: '大区不允许为空',
  common_message_country_empty: '国家/地区不允许为空',
  common_message_site_empty: '工厂不允许为空',
  user_new_profile_save: '创建了新的配置文档',
  common_in_privileges: '特殊权限',
  common_download_files: '下载文件',
  pdf_viewer: 'PDF预览',
  common_all: '全部',
  common_continue: '继续',
  common_minimum_score: '最小',
  common_maximum_score: '最大',
  common_average_score: '平均',
  file_upload_fail: '未能上传文件，出现了以下错误',
  file_upload_success_with_error: '上传成功，但以下除外：',
  missing_fields: '缺失字段',
  column_error: '列错误',
  common_line: '行',
  file_no_samples: '文档上没有找到样品！',
  file_success_upload: '成功上传文件！',
  file_duplicated_kpi_project: '重复的KPI项目',
  round_schedule_maximum_length: '一轮品评的名称最大长度为40个字符。',
  sample_malt_varieties: '麦芽品种',
  error_sample_already_in_round: '您选择的样品在本轮品评计划中已存在',
  malt_varieties_has_no_english_name: '您必须至少有一个麦芽品种名称。',
  malt_varieties_duplicate: '注意：已经有麦芽品种使用这个名字',
  malt_varieties_remove: '去除种类',
  malt_varieties_new: '新种类',
  reports_no_dates: '开始日期和结束日期为必填项！',
  reports_date_range_message: '日期范围不能大于1年',
  round_save_select_site: '要保存一轮，必须选择一个工厂',
  common_error: '出现问题，请再试一次',
  common_horizontal: '橫向布局',
  common_unit: '单位',
  common_supplier: '供应商',
  common_flavor: '风味',
  concentration_of_the_base_beer: '基酒的浓度',
  threshold_aml_method: 'AML方法',
  threshold_component_concentration: '成分浓度',
  threshold_sample_different_help: '哪个样品与其他样品味道不同？',
  threshold_each_set_random_order: '每一组的顺序是随机的',
  threshold_select_base_beer: '选择基酒',
  common_unity: '统一',
  common_finish_session: '完成品评',
  review_submit_threshold: '您确定要保存吗？',
  threshold_coder_wrong: '您的座位号是错误的。请输入正确的座位号！',
  threshold_didnt_enter_the_code: '请输入您的座位号！',
  code: '座位',
  common_batch_code: '批号',
  threshold_samples_required: '需要基酒和风味',
  threshold_information_helper_sample: '请单击“保存”按钮以保留您的更改',
  code_digits: '输入分配给您的 3 位数座位号',
  threshold_sample_spiked_with: '在每组中，选择已加标的样品',
  threshold_code_already_tasted: '此座号已被其他用户用过。输入另一个座号',
  common_kpi_brands: 'KPI品牌',
  common_year: '年',
  common_new_kpi_brand: '新的KPI品牌',
  common_action: '操作',
  common_comment_by_category: '按种类的评语',
  common_alphabetic_comment: '按字母表顺序的评语',
  common_name_pts_code: 'PTS码',
  common_name_pts_code_placeholder: '代码',
  common_name_process_item: '过程项',
  common_name_process_item_placeholder: '项',
  common_global_brands: '全球品牌',
  common_global_brand: '全球品牌',
  common_target_global_brand: '全球品牌KPI目标值',
  common_focus_brand: '焦点品牌',
  common_target_focus_brand: '焦点品牌KPI目标值',
  common_strategic_brand: '战略品牌',
  common_target_strategic_brand: '战略品牌KPI目标值',
  common_maximun_sites: '最大对比工厂数为：',
  common_state_reason_rejecting: '请说明不批准此工厂的原因',
  common_rejected_note: '备注',
  report_list_report_kpi: '关键绩效指标报告',
  report_list_report_taste: '品评报告（按方法)',
  select_brand_to_filter: '选择全球品牌！',
  select_year_to_filter: '选择年份！',
  kpi_only_numbers_warning: '仅接受目标值上的数字',
  kpi_max_number_range_warning: '您的目标品牌必须介于1到9之间',
  kpi_already_selected_brand_warning: '您已选择此品牌，请选择其他品牌',
  kpi_no_kpi_informed_warning: '您没有任何增值',
  kpi_select_year_warning: '选择年份',
  kpi_select_site_warning: '选择一个工厂',
  kpi_existing_configuration_warning: '您已提交过相同工厂和年份的KPI',
  kpi_target_empty_warning: '您的一个品牌的目标是空的',
  kpi_saved_successfully: '您的值已成功保存!',
  kpi_submitted_successfully: '您的值已提交成功！',
  kpi_max_decimals_warning: '您的目标值不得超过小数点后3位',
  kpi_count_as_kpi_sample: '计入 KPI',
  'samples_uncheck_kpi_sample_tooltip ':
    '如果您不希望此样品计入KPI，请取消选中此框',
  reports_mandatory_zone_filter: '至少选择一个区域',
  common_approve: '批准',
  zone_can_not_empty: '区域不能为空!',
  custom_scale_title: '自定义评分表',
  custom_scale_grid_header_score: '分数范围',
  custom_scale_grid_header_ageind_scale: '老化程度',
  common_approve_reject: '批准/拒绝',
  unique_id: '唯一ID',
  'the_value_cannot_be_null ': '数值不能为空！',
  'report_filter_rounds_description ': '在搜索品评计划之前，您必须填好必填项',
  reports_mandatory_test_type: '请选择测试类型.',
  round_reports_month_warning: '开始日期和结束日期之间的时间段最长为6个月。',
  submission_type: '提交类型',
  flavor_identification: '风味物质识别',
  beer_batch: '批次',
  list_of_beers: '啤酒列表',
  random: '随机',
  preview: '座位预览',
  select_the_flavor: '选择风味',
  flavor_threshold: '风味物质阈值',
  input_digital_number: '请输入整数',
  concentration: '浓度',
  flavor_order: '风味物质顺序',
  last: '上一页',
  tasting: '品评',
  confirmation: '确认',
  do_you_confirm_the_evaluation_of_this_sample: '是否确认提交对此样品的品评？',
  confirm: '确认',
  evaluate: '品评',
  correct_answer: '正确答案',
  threshold: '阈值',
  let_a_comment_about_the_tasting: '给此次品评添加评语',
  send: '发送',
  right_now: '现在',
  minute_ago: '分钟之前',
  hour_ago: '小时之前',
  day_before: '天之前',
  year_ago: '年之前',
  add_a_flavor: '添加一个风味',
  column_options: '配置列',
  select_which_of_the_columns_do_you_want_to_be_displayed: '选择要显示的列。',
  soft_drinks: '软饮料',
  soft_drinks_brand: '软饮料品牌',
  soft_drinks_brand_target: '软饮料品牌目标',
  countdown: '倒计时',
  less_than_five_minutes: '当前剩余时间不足5分钟,请联系协调员延长结束时间。',
  the_countdown_to_the_evaluation_has_ended: '品评倒计时已结束，无法进入品评。',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    '计算你需要多少时间来品尝完这些样品',
  only_six_flavors_can_be_added_at_most: '至多只能添加六种风味!',
  concentration_of_the_flavor: '风味浓度',
  input_value: '请输入',
  Concentration_of_the_flavor_is_required: '风味浓度不能为空！',
  previous_round: '上一轮',
  next_round: '下一轮',
  the_end_time_is_greater_than_the_current_time:
    '结束时间必须大于当前时间，如果选择的天与当天相同，请先选择小时和分钟!',
  check_the_answer_at_the_end_of_the_countdown:
    '倒计时还未结束，您可在倒计时结束后查看答案',
  incremental_step: '浓度增长系数',
  set3_concentration: 'Set3 浓度',
  concentration_increments_between_levels: '浓度逐级等量递增',
  incremental_set3_concentration_required: '需要浓度增长系数和Set3浓度',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'Sprint 编号必须大于 0',
  sprint_notes_at_least_one_note: '冲刺笔记必须至少有一个笔记',
  sprint_patch_notes_success_update: 'Sprint 补丁说明更新成功.',
  sprint_patch_notes_error_update: '无法保存笔记.',
  update_sprint_patch_notes: '更新 Sprint 补丁说明',
  sprint_number: '冲刺号码',
  sprint_patch_notes: 'Sprint 补丁说明',
  note: '笔记',
  select_all: '全选',
  please_add_samples_first: '请先添加sample',
  reverse: '翻转',
  score_scale: '分数范围',
  description: '描述',
  aditional_criteria: '额外的标准',
  new_test: '新增测试',
  triangle_test_name: '三角测试名称',
  triangle_test: '三角测试',
  you_can_not_set_three_identical_samples: '不可设置三个相同选项',
  its_mandatory_to_input_test_name: '请填写测试名称',
  its_mandatory_to_input_sample_information: '请填写样品信息',
  attention: '提示',
  test_number: '测试序号',
  you_selected_the: '您选择了',
  next_sample: '下一个样品',
  remove_triangle_test: '删除测试',
  test: '测试',
  confirm_remove: '是否确认删除测试',
  you_can_only_select_up_to_five: '最多允许选择五个',
  reports_mandatory_zone_country_site_filter: '大区,国家,工厂是必填的!',
  reports_mandatory_brand_filter: '至少选择一个品牌',
  reports_by_producing_site: '品评报告（按工厂/品牌)',
  download_label: '下载按钮',
  standard: '标准值',
  input_test: '结果录入',
  detecting: '检测中...',
  connected: '已连接',
  current_taster: '当前品评员',
  alcohol_concentration: '酒精浓度',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    '您的血液酒精浓度超标。请晚些重新进行测试！',
  alcohol_test_qualified: '酒精检测合格',
  configuration: '配置',
  input_result: '结果录入',
  maximum_alcohol_concentration: '最大血液酒精浓度',
  breathalyzer: '酒精检测仪',
  please_input_a_positive_number_up_to_n_decimal_places:
    '请输入正数且最多{{field}}位小数',
  driving: '是否驾车离厂',
  test_time: '测试时间',
  input_type: '录入方式',
  list_breathalyzer: '酒精检测',
  Manual: '手动输入',
  Breathalyzer: '酒精检测仪',
  you_must_input: '您必须输入',
  you_do_not_have_privilege_to_export_this_report: '您没有权限导出此报告',
  competitor_beer: '竞品',
  split_round: '拆分本轮',
  reset: '重置',
  how_many_rounds_splitting_do_you_want_to_make: '请选择您想拆分的轮数',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    '您不能拆分已经拆分过或进行中的轮次',
  hide_sample_details: '隐藏样品信息',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    '以下信息会对品评员隐藏：工厂，生产日期，品牌，麦芽品种和批次号',
  data_will_be_hidden_for_ok_nok: '全部信息会对品评员隐藏',
  you_can_not_add_duplicate_attributes: '请勿选择重复的风味物质属性',
  menu: '菜单',
  link_breathalyzer: '已连接',
  not_link_breathalyzer: '未连接',
  breathalyzer_to_link: '连接酒精检测仪',
  breathalyzer_can_not_to_link_for_this_browser:
    '请在PC端使用谷歌Chrome浏览器进行酒精检测仪数据读取',
  reopen: 'Reopen',
  round_reopen_message: '您确定要reopen这一轮品评吗',
  common_production_line: '生产线',
  common_packaged_beer_data_code: '喷码',
  common_masking_process_for_bottles: '是否添加美容剂',
  common_str_number_days_over_aged: '超龄天数',
  common_over_aged_beer: '是否超龄酒',
  sample_successfully_swapped: '样品替换成功。',
  scoring: '评分',
  configure_available_flavors: '配置风味物质选项',
  process_check: '在线品评',
  new_process_check: '创建在线品评',
  edit_process_check: '编辑在线品评',
  remove_process_check: '删除在线品评',
  common_package: '包装',
  common_department: '部门',
  common_shift: '班次',
  common_tank: '锅号/罐号',
  common_oknok: 'OKNOK',
  common_remark: '备注',
  show_all_process_check: '展示所有在线品评数据',
  show_all_process_check_help_circle:
    '显示从今天起半年内创建的数据，如果还需查看从今天起一年内创建的数据，请选中此框',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    '取样时间不能大于当前时间!',
  common_job_position: '岗位',
  decimal_sep: '小数分隔符',
  is_swapped_to: '被更换为',
  review_warning_comment_title: '您有未填写评语的样品!',
  continuous_typing: '连续输入',
  continuous_typing_tips: '选择或输入"程度"后持续输入"属性"',
  BSSI_Reports: 'BSSI 报告',
  data_analysis_sip: '数据分析 - SIP',
  select_the_color: '选择一种颜色',
  Color: '颜色',
  download_document: '下载文件',
  Month_of_Discontinued_Production: '停产月份',
  Suspension_List: '停产列表',
  New_Suspension: '新停产申请',
  new_suspension_page: '新停产信息',
  edit_suspension_page: '编辑停产信息',
  Brand_name: '品牌名',
  List_Brewery_Production_Suspension: '工厂停产列表',
  Upload_successfully: '文件上传成功',
  training_materials: '点击查看培训材料',
  Remove_Suspension: '删除停产信息：',
  Add_Absence_Reason: '添加缺勤原因',
  Reason: '请假事由',
  You_have_duplicate_absence_dates: '您有重复的缺勤日期',
  Remove_Absence: '删除缺勤信息：',
  Absence_Title: '请选择缺席品评的时间和请假事由',
  Shipping_Site: '收货工厂',
  shipping_received: '到货确认',
  receive_sample_alert: '您确定已经收到样品了吗？',
  receive_uncheck_alert: '您确定要取消勾选吗？',
  all_received: '全部接收',
  not_all_received: '未全部接受',
  not_for_shipping: '无需邮寄',
  receive_hint: '选择将接收该样品的所有工厂',
  absence_reason: '缺席原因',
  Page1: '第 {{field}} 页',
  Search: '搜索',
  page_total: '总数',
  start_date: '开始日期',
  end_date: '结束日期',
  ok_for_all: '一键OK',
  last_days: '近 {{field}} 天',
  today: '今天',
  its_mandatory_to_select_sample_A_and_sample_B: '请选择样品A和样品B',
  its_mandatory_to_select_sample_A: '请选择样品A',
  its_mandatory_to_select_sample_B: '请选择样品B',
  triangle_comment: '备注',
  select_sample: '选择样品',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    '样品日期时间段筛选，默认近7天，且基于所筛选时间段导出品评数据。',
  select_all_flavor: '选择所有风味',
  deselect_all: '取消全选',
  email_distribution: '邮件分发',
  new_email_distribution: '新建邮件分发',
  edit_email_distribution: '编辑邮件分发',
  distribution_type: '分发类型',
  the_score_entered_is_invalid:
    '您输入的分数无效。请参考分数表，重新输入您的分数。',
  service_now: '点击创建一个ServiceNow Ticket，来报告问题并获取帮助',
  service_now_tips:
    '如果您在系统的使用过程中遇到任何问题或希望得到帮助，您可以点击这里到ServiceNow中发送报告，我们的支持团队将尽快与您取得联系。',
  done: '完成',
  changes_you_made_may_not_be_saved:
    '您所做的更改可能无法保存。是否要继续并丢失更改？',
  cannel_auto_save_tips:
    '品评页面的性能已进行提升，请不要忘记点击完成品评来保存和提交您的品评结果。',
  list_email_page_tips:
    '了解如何设置KPI样品以在大区月度报告里展示对应数据统计。',
  list_email_page_helps:
    '请点击查看创建MCS品评计划的引导，以便获得正确的大区MCS月报数据。',
  kpi_instruction: 'KPI样品介绍',
  group_no_recipients_message: '至少需要添加一个收件人',
  the_current_distribution_type_already_exists:
    '当前类型已存在，请重新选择邮件类型或返回列表。',
  distribution_type_message_empty: '分发类型不允许为空',
  add_samples: '添加样品轮次中',
  copy_this_sample: '复制样品',
  remove_this_sample: '删除样品',
  multiple_selection_tips:
    '您可以为同一样本类型同时选择多个样本。点击"+"和"-"进行尝试。',
  all_add_once_tips: "单击'全选'按钮，为每个选定的样本类型自动添加1个样本",
  copy_confirm_tip: '是否确定要复制该样品？',
  user_non_abi_user: '新增临时用户',
  language_of_email_invitation: '邮件邀请语言',
  add_non_abi_users_to_round:
    '临时品评员会收到一封内附链接的邮件邀请，通过点击链接参与对应品评计划',
  change_non_abi_users_to_round:
    '此处邀请的临时用户会收到一封内附链接的邮件，通过点击链接参与此品评计划。',
  guest: '来访人员',
  email_to_all: '发送邀请邮件',
  send_email: '发送邀请邮件',
  temporary_non_abi_taster: '临时品评员',
  user_type: '用户类型',
  email_invitation_delivery_request_sent: '计划邀请邮件已发送！',
  incorrect_e_mail_formatting: '电子邮件格式不正确',
  all_tasters: '全部品评员',
  key_tasters: '关键品评员',
  non_key_tasters: '非关键品评员',
  temporary: '临时用户',
  maximum_number_of_samples_is: '最大样品数为',
  sample_added: '已添加样品',
  click_to_view_scale_of_flavour_intensities: '点击链接查看风味程度表',
};
