import { Tour, ConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import { useUser } from '../../contexts/User';
import { useTourMask } from './tourMaskHooks';

const TourMask = props => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const hasHideTour = localStorage.getItem(user.str_abi_id) && typeof (JSON.parse(localStorage.getItem(user.str_abi_id))) === 'object' ? JSON.parse(localStorage.getItem(user.str_abi_id)) : []
  const { onFinish, onChange, placement } = props
  const [steps, setSteps] = useState([])
  const [openName, setOpenName] = useState([])
  const defaultTipsName = []
  const { isOpenSum } = useTourMask();

  useEffect(() => {
    setTimeout(() => {
      const arr = (props.steps ?? [
        {
          title: '',
          description: <div>
            <p>New SSO applied, it will save your login credential now, but be aware to logout your account for non-personal device.</p>
            <br />
            <p >Please ACCEPT the Microsoft permission for Sensory-Global-PROD when you login the first time.</p>
          </div>,
          target: document.querySelector('#sso'),
          tipsName: 'sso',
        },
      ]).filter((item) => item.target && hasHideTour.indexOf(item.tipsName) === -1)
      arr.forEach((item, index) => {
        if (!item.nextButtonProps) {
          item['nextButtonProps'] = {
            children: <span>{index + 1 !== arr.length ? t('common_next') : t('done')}</span>
          }
        }
        if (!item.prevButtonProps) {
          item['prevButtonProps'] = {
            style: { border: '1px solid #F49B00', color: '#F49B00' },
            children: <span>{t('last')}</span>
          }
        }
        if (!item.closeIcon) {
          item['closeIcon'] = false
        }
      })
      setSteps(arr)
      setOpenName(arr.map(item => item.tipsName))
      if (arr.length && hasHideTour.indexOf(arr[0].tipsName) === -1) {
        setOpen(true)
      }
    }, 750);
  }, [isOpenSum])

  const onClose = () => {
    const arr = JSON.stringify([...[...hasHideTour, ...openName].filter(item => defaultTipsName.includes(item))])
    localStorage.setItem(user.str_abi_id ?? 'local', arr)
    setOpen(false)
  }

  return <>
    <ConfigProvider
      locale={i18n.language.toUpperCase() === 'ZH' ? zhCN : enUS}
      theme={{
        components: {
          Tour: {
            'closeBtnSize': '16',
          },
        },
        token: {
          'colorPrimary': '#F49B00',
        },
      }}
    >
      <Tour
        open={open}
        onClose={onClose}
        steps={steps}
        onFinish={() => {
          onFinish && onFinish();
        }}
        onChange={(number) => {
          onChange && onChange(number)
        }}
        placement={placement ?? 'bottomLeft'}
        indicatorsRender={(current, total) => {
          return <p style={{ color: '#F49B00' }}>{current + 1}/{total}</p>
        }}
      />
    </ConfigProvider>
  </>
}


export default TourMask;